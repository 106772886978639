<div class="medical-records-container">
  <div class="row align-items-center justify-content-end p-3">
    <div class="col-auto">
      <button mat-flat-button class="btn-primary" (click)="openMedicalRecordDialog()">Adicionar prontuário</button>
    </div>
  </div>
  <div class="row p-3">
    <ng-container *ngFor="let record of records">
      <div class="col-12">
        <div class="row record mb-3">
          <div class="col-12 record-avaliacao">
            {{ record.descricaoEmpresaOrigem }} | {{ record.descricaoTipoAtendimentoPrincipal }}
          </div>
          <div class="col-12 record-descricao mt-3">
            {{ record.nomeProfissional }}
          </div>
          <div class="col-12 record-descricao mt-3" [innerHTML]="record.descricao">
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <mat-paginator [length]="pageControl.count" [pageIndex]="pageControl.page" [pageSizeOptions]="[10, 25, 50, 100]"
    [pageSize]="pageControl.limit" (page)="pageEvent($event)"></mat-paginator>
</div>
